@mixin __FF_SAME_ORIGIN_GRAPHQL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ADD_TO_CART_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_IN_HEAD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_ASYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_INVENTORY_VEHICLEINFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_WIPER_AVAILABILITY_FOR_STORE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GEOPOINT_DIRECTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_EMPLOYEE__DISCOUNT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_ALLOW_OPTIONAL_AND_PARTIAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INSTANT_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_IS_APPOINTMENT_STANDARD_QUOTE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_KEYWORD_REDIRECT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NO_STORE_IN_AREA_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LICENSE_LOOKUP_FIELDS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_CART_ACCOUNT_DRAWER__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_MY_ACCOUNT_($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PERSIST_MULTISTORE_PLP_PRODUCT_TO_PDP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PDP_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_TREADWELL_DATA_NONTREADWELL_PLP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_OPTIONAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_CONFIRMATION_SURVEY_INTEGRATION__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PARTYTOWN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_360_WHEEL_VIEW__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STANDARD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STAGGERED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_PREBUILT_PACKAGES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_CANONICAL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PREFETCH_APPOINTMENTS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_COMPARE_WITH_CONTEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_IMAGE_FORMATS_AUTO_SYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_RECONCILIATION_CALL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_RENAME_TREADWELL_CONTENT_QUERY_($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SALESFORCE_CHAT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SERVICE_APPOINTMENT_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SESSION_TIMEOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SOURCE_DEFENSE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_STORE_IN_DIFFERENT_STATE_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TIRES_THAT_DONT_FIT_MESSAGING__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_LANDING_PAGE_CMS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_UTAG_SYNC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WHEEL_VISUALIZER_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_AFFIRM_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_APPLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GOOGLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PAYPAL_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.message {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding: 4px 16px;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: $bolder;
  color: $black;
  border-radius: 4px;
}

.icon {
  padding: 4px 8px 0 0;
  font-size: rem(16);
}

.success {
  @extend .message;
  background-color: $green-50;

  .icon {
    color: $green-700;
  }
}

.warning {
  @extend .message;

  background-color: $gold-50;

  .icon {
    color: $gold-700;
  }
}

.error {
  @extend .message;

  background-color: $red-50;

  .icon {
    color: $red-700;
  }
}

.solid-info {
  @extend .message;

  background-color: $blue-50;

  .icon {
    color: $blue-700;
  }
}

.center {
  justify-content: center;
}

.info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $blue-700;
  border-top: 1px solid $blue-700;
  padding: 7px 20px;
  min-height: 40px;
  transition: border 0.7s;
  position: relative;

  .icon {
    position: static;
    color: $blue-700;
    margin-right: 8px;
  }

  .message {
    color: $black;
  }
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media($min-lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.secondary-message {
  font-weight: normal;
  font-style: italic;
  font-size: rem(14);
  margin-top: 10px;

  @include media($min-lg) {
    margin: 0;
  }
}

.btn-padding {
  padding: 7px 30px;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
