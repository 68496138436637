@import './dt-media-queries';

// font weights
$lighter: 400;
$normal: 500;
$bold: 800;
$bolder: 900;

%h1 {
  font-size: rem(32.44);
  line-height: rem(40);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(36.49);
    line-height: rem(48);
  }
}

%h2 {
  font-size: rem(28.83);
  line-height: rem(32);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(32.44);
    line-height: rem(40);
  }
}

%h3 {
  font-size: rem(25.63);
  line-height: rem(32);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(28.83);
    line-height: rem(32);
  }
}

%h4 {
  font-size: rem(22.78);
  line-height: rem(32);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(25.63);
    line-height: rem(32);
  }
}

%h5 {
  font-size: rem(20.25);
  line-height: rem(24);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(22.78);
    line-height: rem(32);
  }
}

%h6 {
  font-size: rem(18);
  line-height: rem(24);
  margin: 0;

  @include media($min-lg) {
    font-size: rem(20.25);
    line-height: rem(24);
  }
}

%p-regular {
  font-size: rem(16);
  line-height: rem(24);
  margin: 0;
}

%p-small {
  font-size: rem(14.22);
  line-height: rem(16);
  margin: 0;
}

%p-extra-small {
  font-size: rem(12.64);
  line-height: rem(16);
  margin: 0;
}
