:global {
  svg:not(:root).svg-inline--fa {
    overflow: visible;
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.225em;
  }
  .svg-inline--fa.fa-w-1 {
    width: 0.0625em;
  }
  .svg-inline--fa.fa-w-2 {
    width: 0.125em;
  }
  .svg-inline--fa.fa-w-3 {
    width: 0.1875em;
  }
  .svg-inline--fa.fa-w-4 {
    width: 0.25em;
  }
  .svg-inline--fa.fa-w-5 {
    width: 0.3125em;
  }
  .svg-inline--fa.fa-w-6 {
    width: 0.375em;
  }
  .svg-inline--fa.fa-w-7 {
    width: 0.4375em;
  }
  .svg-inline--fa.fa-w-8 {
    width: 0.5em;
  }
  .svg-inline--fa.fa-w-9 {
    width: 0.5625em;
  }
  .svg-inline--fa.fa-w-10 {
    width: 0.625em;
  }
  .svg-inline--fa.fa-w-11 {
    width: 0.6875em;
  }
  .svg-inline--fa.fa-w-12 {
    width: 0.75em;
  }
  .svg-inline--fa.fa-w-13 {
    width: 0.8125em;
  }
  .svg-inline--fa.fa-w-14 {
    width: 0.875em;
  }
  .svg-inline--fa.fa-w-15 {
    width: 0.9375em;
  }
  .svg-inline--fa.fa-w-16 {
    width: 1em;
  }
  .svg-inline--fa.fa-w-17 {
    width: 1.0625em;
  }
  .svg-inline--fa.fa-w-18 {
    width: 1.125em;
  }
  .svg-inline--fa.fa-w-19 {
    width: 1.1875em;
  }
  .svg-inline--fa.fa-w-20 {
    width: 1.25em;
  }
  .svg-inline--fa.fa-li {
    width: 2em;
  }
  .svg-inline--fa.fa-fw {
    width: 1.25em;
  }

  .fa-layers svg.svg-inline--fa {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -0.125em;
    width: 1em;
  }
  .fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  .fa-layers-text,
  .fa-layers-counter {
    display: inline-block;
    position: absolute;
    text-align: center;
  }

  .fa-layers-text {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  .fa-layers-counter {
    background-color: #ff253a;
    border-radius: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    height: 1.5em;
    line-height: 1;
    max-width: 5em;
    min-width: 1.5em;
    overflow: hidden;
    padding: 0.25em;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }

  .fa-layers-bottom-right {
    bottom: 0;
    right: 0;
    top: auto;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }

  .fa-layers-bottom-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }

  .fa-layers-top-right {
    right: 0;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }

  .fa-layers-top-left {
    left: 0;
    right: auto;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }

  .fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
  }

  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .fa-stack {
    display: inline-block;
    height: 2em;
    position: relative;
    width: 2.5em;
  }

  .fa-stack-1x,
  .fa-stack-2x {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .svg-inline--fa.fa-stack-1x {
    height: 1em;
    width: 1.25em;
  }

  .svg-inline--fa.fa-stack-2x {
    height: 2em;
    width: 2.5em;
  }

  .fa-inverse {
    color: #fff;
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
